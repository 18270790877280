import { reactive, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line object-curly-newline
import { required, email } from '@vuelidate/validators';

export const useContactForm = () => {
    const state = reactive({
        id: '',
        nombre: '',
        telefono: '',
        email: '',
        asunto: '',
        mensaje: '',
        status: 1,
    });

    const setState = (c) => {
        state.id = c.id;
        state.nombre = c.name;
        state.telefono = c.phone;
        state.email = c.email;
        state.asunto = c.subject;
        state.mensaje = c.message;
        state.status = c.status;
    };

    const rules = computed(() => ({
        nombre: { required },
        telefono: { required },
        email: { required, email },
        asunto: { required },
        mensaje: { required },
    }));

    const $v = useVuelidate(rules, state);

    return {
        state,
        setState,
        $v,
        rules
    };
};

export default {};

import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const contact = ref(null);

    const createContact = async (data) => {
        const res = await axios.post('/contact/', data);
        if (res.status === 201) {
            contact.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getContact = async (id) => {
        const res = await axios.get(`/contact/${id}`);
        if (res.status === 200) {
            contact.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const closeContact = async (id) => {
        const res = await axios.post(`/contact/close/${id}`);
        if (res.status === 200) {
            contact.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        contact,
        createContact,
        getContact,
        closeContact,
    };
}
